<template>
  <div
    class="my-5"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : 'px-5'"
  >
    <!-- ==== FILTER SUMMARY ==== -->
    <v-row justify="start">
      <v-col cols="12" md="6">
        <!-- <v-text-field
          readonly
          v-if="store.length < 2"
          label="Pilih Rekapan"
          color="#d31145"
          v-model="storeName"
          dense
          outlined
        >
        </v-text-field> -->
        <v-select
          outlined
          dense
          v-model="filter.recap_job_id"
          color="#d31145"
          attach
          :items="recapJob"
          item-text="name"
          item-value="id"
          :hide-details="true"
          label="Pilih Rekapan"
        >
        </v-select>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <date-picker
          v-model="value2"
          type="date"
          format="DD MMMM YYYY"
          range
          :clearable="false"
          :disabled-date="disabledAfterThisDay"
          :shortcuts="shortcuts"
          placeholder="Pilih Tanggal"
        >
          <template v-slot:header>
            <div class="title-date">Pilih Tanggal</div>
          </template>
        </date-picker>
      </v-col>
      <v-col cols="12" md="4">
        <VueCtkDateTimePicker
            color="#d31145"
            v-model="filter.filterdate"
            label="Pilih Tanggal"
            :range="true"
            :noClearButton="true"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="filter.dateNow"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
            ]"
          />
      </v-col> -->
    </v-row>

    <p class="body-1 px-5 font-weight-bold text-second pt-0">SUMMARY FRANCHISE</p>
    <v-divider class="pb-5"></v-divider>

    <!-- ==== DATA SUMMARY PENJUALAN ==== -->
    <v-card outlined class="box-shadow border-radius pa-5 pb-1">
      <v-card-title class="body-1 px-5 text-second pt-0">
        JUMLAH MERCHANT DAN OUTLET
      </v-card-title>
      <!-- <v-card-subtitle class="caption pl-5 text-third">
        <b>{{ filtered }}</b>
      </v-card-subtitle> -->
      <v-row class="my-3 mb-6">
        <v-col
          cols="12"
          sm="6"
          lg="6"
          md="6"
          xs="12"
          v-for="(item, index) in process.run ? 6 : sales_summary"
          :key="index"
        >
          <v-skeleton-loader
            class="ma-auto"
            height="200"
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="image"
          >
            <v-card 
              :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
              class="box-shadow border-radius py-5">
              <v-card-title class="subtitle-1 font-weight-bold">
                {{ item.title }}
                <v-spacer></v-spacer>
                <v-tooltip left v-if="item.help">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#D31145"
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer"
                      size="20"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <div v-html="item.help"></div>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="subtitle-2">
                <p
                  class="text-second d-flex align-center"
                  style="font-size: 25px;"
                >
                  <span class="mr-3 mt-3">
                    {{ item.value }}
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon size="40" :color="item.color">{{
                      item.icon
                    }}</v-icon>
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>

    <!-- ==== DATA PERTUMBUHAN ==== -->
    <!-- <v-row>
      <v-col cols="12" md="12" class="mt-6">
        <v-skeleton-loader
          class="ma-auto"
          transition="scale-transition"
          :tile="true"
          type="card"
        >
          <v-card 
            outlined 
            class="box-shadow border-radius py-5">
            <v-card-title class="body-1 px-5 text-second pt-0 flex justify-space-between">
              <div>
                TOTAL PERTUMBUHAN OUTLET
              </div>
              <div style="width: 400px">
                <v-spacer></v-spacer>
                <VueCtkDateTimePicker
                  color="#d31145"
                  onlyDate
                  :range="true"
                  right
                  v-model="filter.filter_date"
                  label="Pilih Tanggal"
                  :noClearButton="true"
                  format="YYYY-MM-DD"
                  formatted="LL"
                  locale="id"
                  :max-date="new Date().toISOString().substr(0, 10)"
                  :custom-shortcuts="[
                    { key: 'today', label: 'Hari ini', value: 'day' },
                    { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
                    { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
                    { key: 'last7Days', label: '7 hari terakhir', value: 7 },
                    { key: 'last30Days', label: '30 hari terakhir', value: 30 },
                    { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
                    { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
                    { key: 'thisYear', label: 'Tahun ini', value: 'year' },
                    { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
                  ]"
                />
              </div>
            </v-card-title>
            <v-card-subtitle class="caption px-5 text-third">
              <b>{{ filtered }}</b>
            </v-card-subtitle>
            <div class="mt-16 pa-3">
              <div v-if="Object.keys(register_bar).length > 0">
                <apexchart
                  height="300"
                  :options="register_bar.chartOptions"
                  :series="register_bar.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="register_bar.series == []"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </div>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { get, post, put, destroy } from "../../service/Axios";
import moment from "moment";
import Empty from "@/components/Empty";
import Export from "@/components/Export";
import { TokenService } from "@/service/Storage.Service";
export default {
  components: {
    Empty,
    Export,
  },
  data() {
    return {
      isLoadAgains: false,
      filter: {
        recap_job_id: "",
        filterdate: {},
        dateNow: new Date().toISOString().substr(0, 10),
      },
      value2: [],
      firstDay: "",
      lastDay: "",
      date1: "",
      date2: "",
      showTimeRangePanel: false,
      shortcuts: [
        {
          text: "Hari Ini",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              // 0
            );
            let lastDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              // 23,
              // 59,
              // 59
            );
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "Hari Kemarin",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate() - 1,
              // 0
            );
            let lastDay = firstDay;
            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: "Minggu Ini",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.setDate(newDate.getDate() - newDate.getDay())
            );
            let lastDay = new Date(
              newDate.setDate(newDate.getDate() - newDate.getDay() + 6)
            );
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "Minggu Kemarin",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.setDate(newDate.getDate() - newDate.getDay() - 7)
            );
            let lastDay = new Date(
              newDate.setDate(newDate.getDate() - newDate.getDay() + 6)
            );
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "Bulan Ini",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              1
            );
            let lastDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth() + 1,
              0
            );
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "Bulan Kemarin",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth() - 1,
              1
            );
            let lastDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              0
            );
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "7 Hari Kemarin",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate() - 7,
              0
            );
            let lastDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              0
            );
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "30 Hari Kemarin",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate() - 30,
              0
            );
            let lastDay = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              0
            );
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "Tahun Ini",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(newDate.getFullYear(), 0);
            let lastDay = new Date(newDate.getFullYear(), 11, 31);
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
        {
          text: "Tahun Kemarin",
          onClick() {
            const newDate = new Date();
            let firstDay = new Date(newDate.getFullYear() - 1, 0);
            let lastDay = new Date(newDate.getFullYear() - 1, 11, 31);
            const date = [firstDay, lastDay];
            // return a Date
            return date;
          },
        },
      ],
      storeName: "",
      contentEmpty: "Data Belum Tersedia",
      dateNow: moment().format("YYYY-MM"),
      monthNow: moment.months(),
      yearNow: new Date().getFullYear(),
      dialogDatePicker: false,
      datePickerBar: false,
      listyear: [],
      listyear1: [],
      dateBar: moment().format("YYYY-MM"),
      grand_total_income: 0,
      recapJob: [],
      top_product: [],
      less_product: [],
      top_products: [],
      detail: {},
      sales_summary: [],
      pie: {},
      daily_bar: {},
      weekly_bar: {},
      hourly_bar: {},
      chartdataProduct: [],
      top_category_title: [],
      top_category_bar: {},
      top_category_bar1: {},
      top_category_bar2: {},
      top_category_bar3: {},
      top_category_bar4: {},
      process: {
        run: false,
      },
      dialog: {
        top_products: false,
        less_products: false,
      },

      /* BASE TABEL */
      displayBaseTable: false,
      displayBaseTableLessProduct: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: "Nama Produk",
          align: "left",
          width: 200,
          value: "sales_order_detail_product_name",
        },
        {
          text: "Item Terjual",
          align: "cenetr",
          width: 100,
          value: "total_item",
        },
        // {
        //   text: "Penjualan Kotor (Rp)",
        //   align: "end",
        //   width: 200,
        //   value: "gross_sales",
        // },
        {
          text: "Penjualan Bersih (Rp)",
          align: "end",
          width: 200,
          value: "nett_sales",
        },
        {
          text: "Laba kotor (Rp)",
          align: "end",
          width: 200,
          value: "gross_profit",
        },
      ],
      headersLessProduct: [
        {
          text: "Nama Produk",
          align: "left",
          width: 200,
          value: "product_name",
        },
        {
          text: "Kategori",
          align: "center",
          width: 200,
          value: "product_category_name",
        },
        {
          text: "Harga Produk (Rp)",
          align: "end",
          width: 200,
          value: "product_price",
        },
      ],
      /* END BASE TABEL*/
    };
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    store() {
      if (JSON.parse(TokenService.getUser()).data_store.length > 1) {
        let toko = [];
        toko = JSON.parse(TokenService.getUser()).data_store;
        toko.splice(0, 0, { store_id: "0", store_name: "Semua Outlet" });
        return toko;
      } else {
        for (let i in JSON.parse(TokenService.getUser()).data_store) {
          if (
            JSON.parse(TokenService.getUser()).data_store[i].store_id ===
            this.user.selected_store_id.toString()
          ) {
            this.storeName = JSON.parse(TokenService.getUser()).data_store[
              i
            ].store_name;
          }
        }
        return JSON.parse(TokenService.getUser()).data_store;
      }
    },
    filtered() {
      return this.value2
        ? moment(this.value2[0]).format("DD MMMM YYYY") +
            " - " +
            moment(this.value2[1]).format("DD MMMM YYYY")
        : "";
    },
    filterdateStart() {
      return this.value2[0]
        ? moment(this.value2[0]).format("YYYY-MM-DD") //HH:mm:ss
        : "";
    },
    filterdateEnd() {
      return this.value2[1]
        ? moment(this.value2[1]).format("YYYY-MM-DD") //HH:mm:ss
        : "";
    },
  },
  watch: {
    "filter.recap_job_id": function(val) {
      this.fetchSummary();
    },
    "filter.filterdate": function(val) {
      this.initialize();
    },
    value2: function(val) {
      this.initialize();
    },
  },
  mounted() {
    // this.fetchSummary();
    this.fetchRecap();
    // this.filter.store_id = this.user.selected_store_id.toString();
    // this.initialize();
    // var date = new Date();
    // var firstdayweek = moment(
    //   new Date(date.setDate(date.getDate() - date.getDay() + 1))
    // ).format("YYYY-MM-DD");
    // var lastdayweek = moment(
    //   new Date(date.setDate(date.getDate() - date.getDay() + 7))
    // ).format("YYYY-MM-DD");
    // let firstDay = new Date(
    //   new Date().setDate(new Date().getDate())
    //   // new Date().setDate(new Date().getDate() - new Date().getDay())
    // );
    // let lastDay = new Date(
    //   new Date().setDate(new Date().getDate())
    //   // new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
    // );

    // const filter = [firstDay, lastDay];
    // // console.log(filter);
    // this.value2 = filter;

    // this.filter.filterdate = {
    //   end: lastdayweek,
    //   shortcut: "isoWeek",
    //   start: firstdayweek,
    // };
  },
  methods: {
    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear();
      var min = 2020;

      for (var i = max; i >= min; i--) {
        this.listyear1.push(i.toString());
      }
    },
    async fetchRecap() {
      await get(`franchise/report/recap_job/transaction`, {

      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.recapJob = res.results.data
            this.filter.recap_job_id = res.results.data[0].id;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    async fetchSummary() {
      await get(`franchise/recap/dashboard/summary_outlet`, {
        params: {
          recap_job_id: this.filter.recap_job_id
        }
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            let summary = res.results.data.array
            this.sales_summary = [
              {
                icon: "mdi-chart-line",
                title: summary[0].label,
                value:
                  this.$options.filters.num_format(
                    summary[0].value
                  ),
                help:
                  "Total Merchant yang bergabung di Franchise",
                color: "#f1c40f",
              },
              {
                icon: "mdi-chart-bell-curve-cumulative",
                title: summary[1].label,
                value:
                  this.$options.filters.num_format(
                    summary[1].value
                  ),
                help:
                  "Total Outlet yang terdaftar di Franchise",
                color: "#9b59b6",
              }
            ];
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    async initialize() {
      this.isLoadAgains = true;
      this.process.run = true;
      await get(`dashboard-rev/list`, {
        params: {
          store_id: this.filter.store_id,
          start_date: this.filterdateStart,
          end_date: this.filterdateEnd,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.isLoadAgains = false;
            this.detail = res.data;

            this.top_product = this.detail.top_product; // STOK REMINDER
            this.less_product = this.detail.less_product; // REMINDER PRODUCT TIDAK TERJUAL
            this.grand_total_income = this.detail.grand_total_income; // INCOME GRAND TOTAL

            // PENJUALAN KOTOR HARIAN
            this.daily_bar = {
              series: [
                {
                  name: "PENJUALAN KOTOR HARIAN",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "PENJUALAN KOTOR HARIAN",
                      },
                      svg: {
                        filename: "PENJUALAN KOTOR HARIAN",
                      },
                      png: {
                        filename: "PENJUALAN KOTOR HARIAN",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#649fe4", "#41af55"],
                labels: [],
                xaxis: {
                  tickAmount: 15,
                  title: {
                    text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `Rp ${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            };

            this.daily_bar.chartOptions.labels = this.detail.chart_daily.label;
            this.daily_bar.series[0].data = this.detail.chart_daily.value;

            // PENJUALAN KOTOR SEMINGGU
            this.weekly_bar = {
              series: [
                {
                  name: "PENJUALAN KOTOR SEMINGGU",
                  type: "column",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "PENJUALAN KOTOR SEMINGGU",
                      },
                      svg: {
                        filename: "PENJUALAN KOTOR SEMINGGU",
                      },
                      png: {
                        filename: "PENJUALAN KOTOR SEMINGGU",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#649fe4", "#41af55"],
                labels: [],
                xaxis: {
                  title: {
                    text: "Hari",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `Rp ${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            };

            this.weekly_bar.chartOptions.labels = this.detail.chart_weekly.label;
            this.weekly_bar.series[0].data = this.detail.chart_weekly.value;

            // PENJUALAN KOTOR PER JAM
            this.hourly_bar = {
              series: [
                {
                  name: "PENJUALAN KOTOR PER JAM",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "PENJUALAN KOTOR PER JAM",
                      },
                      svg: {
                        filename: "PENJUALAN KOTOR PER JAM",
                      },
                      png: {
                        filename: "PENJUALAN KOTOR PER JAM",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#41af55"],
                labels: [],
                xaxis: {
                  title: {
                    text: "Waktu",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `Rp ${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            };

            this.hourly_bar.chartOptions.labels = this.detail.chart_hourly.label;
            this.hourly_bar.series[0].data = this.detail.chart_hourly.value;

            // GRAFIK PIE
            this.pie = {
              categoryVolume: {
                series: [],
                chartOptions: {
                  chart: {
                    width: 500,
                    type: "pie",
                    toolbar: {
                      show: true,
                      export: {
                        csv: {
                          filename: "KATEGORI BERDASARKAN VOLUME",
                        },
                        svg: {
                          filename: "KATEGORI BERDASARKAN VOLUME",
                        },
                        png: {
                          filename: "KATEGORI BERDASARKAN VOLUME",
                        }
                      }
                    },
                    zoom: {
                      enabled: true,
                      type: 'y',
                    },
                  },
                  labels: [],
                  colors: [
                    "#2ecc71",
                    "#f1c40f",
                    "#9b59b6",
                    "#3498db",
                    "#e74c3c",
                  ],
                  dataLabels: {
                    enabled: true,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  legend: {
                    position: "right",
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: false,
                        },
                      },
                    },
                  },
                  responsive: [
                    {
                      breakpoint: 450,
                      options: {
                        chart: {
                          width: 300,
                        },
                        legend: {
                          position: "bottom",
                        },
                      },
                    },
                  ],
                },
              },
              categorySales: {
                series: [],
                chartOptions: {
                  chart: {
                    width: 500,
                    type: "pie",
                    toolbar: {
                      show: true,
                      export: {
                        csv: {
                          filename: "KATEGORI MENURUT PENJUALAN",
                        },
                        svg: {
                          filename: "KATEGORI MENURUT PENJUALAN",
                        },
                        png: {
                          filename: "KATEGORI MENURUT PENJUALAN",
                        }
                      }
                    },
                    zoom: {
                      enabled: true,
                      type: 'y',
                    },
                  },
                  labels: [],
                  colors: [
                    "#2ecc71",
                    "#f1c40f",
                    "#9b59b6",
                    "#3498db",
                    "#e74c3c",
                  ],
                  dataLabels: {
                    enabled: true,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  legend: {
                    position: "right",
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: false,
                        },
                      },
                    },
                  },
                  responsive: [
                    {
                      breakpoint: 450,
                      options: {
                        chart: {
                          width: 300,
                        },
                        legend: {
                          position: "bottom",
                        },
                      },
                    },
                  ],
                },
              },
            };

            // TOP CATEGORY VOLUME
            this.pie.categoryVolume.series = this.detail.chart_category_by_volume.value;
            this.pie.categoryVolume.chartOptions.labels = this.detail.chart_category_by_volume.label;

            // TOP CATEGORY SALES
            this.pie.categorySales.series = this.detail.chart_category_by_sales.value;
            this.pie.categorySales.chartOptions.labels = this.detail.chart_category_by_sales.label;

            // // PENJUALAN ITEM TOP CATEGORY
            this.top_category_bar = {
              series: [],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: false,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "ITEM TOP BERDASARKAN KATEGORI",
                      },
                      svg: {
                        filename: "ITEM TOP BERDASARKAN KATEGORI",
                      },
                      png: {
                        filename: "ITEM TOP BERDASARKAN KATEGORI",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                    type: 'y',
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: [
                  "#2ecc71",
                  "#f1c40f",
                  "#9b59b6",
                  "#3498db",
                  "#e74c3c",
                  "#d31145",
                  "#e67e22",
                  "#f1c40f",
                  "#1abc9c",
                  "#ff6b81",
                  "#2ed573",
                  "#fa983a",
                ],
                labels: [],
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `Rp ${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            };

            this.chartdataProduct = this.detail.chart_top_category;

            if (this.detail.chart_top_category.length > 0) {
              // TOP CATEGORY SALES

              this.chartdataProduct.forEach((item) => {
                item.chartOptions = {
                  chart: {
                    animations: {
                      enabled: true,
                      easing: "linear",
                      dynamicAnimation: {
                        speed: 1000,
                      },
                    },
                    toolbar: {
                      show: true,
                      export: {
                        csv: {
                          filename: "ITEM TOP BERDASARKAN KATEGORI",
                        },
                        svg: {
                          filename: "ITEM TOP BERDASARKAN KATEGORI",
                        },
                        png: {
                          filename: "ITEM TOP BERDASARKAN KATEGORI",
                        }
                      }
                    },
                    zoom: {
                      enabled: true,
                      type: 'y',
                    },
                  },
                  stroke: {
                    curve: "smooth",
                    width: [3, 3],
                  },
                  colors: [
                    "#2ecc71",
                    "#f1c40f",
                    "#9b59b6",
                    "#3498db",
                    "#e74c3c",
                    "#d31145",
                    "#e67e22",
                    "#f1c40f",
                    "#1abc9c",
                    "#ff6b81",
                    "#2ed573",
                    "#fa983a",
                  ],
                  labels: item.products.label,
                  yaxis: {
                    labels: {
                      formatter: function(val, index) {
                        let price = "";
                        const reverseNumber = val
                          .toString()
                          .split("")
                          .reverse()
                          .join("");
                        const arrReverseNumber = [
                          ...Array(reverseNumber.length).keys(),
                        ];
                        arrReverseNumber.map((index) => {
                          if (index % 3 === 0)
                            price += reverseNumber.substr(index, 3) + ".";
                        });
                        return `${price
                          .split("", price.length - 1)
                          .reverse()
                          .join("")}`;
                      },
                    },
                    title: {
                      offsetX: 0,
                    },
                  },
                };
              });
            }

            this.process.run = false;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    // BASE TABLE
    responseDataStart() {
      this.displayBaseTable = false;
      this.emptyData = false;
      this.loading = true;
      this.process.run = true;

      //DATE
      this.$refs["base-table"].params.store_id = this.filter.store_id;
      this.$refs["base-table"].params.start_date = this.filterdateStart;
      this.$refs["base-table"].params.end_date = this.filterdateEnd;

    },
    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      // this.total = res.data.summary;
      if (res.data.results.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },
    responseDataStartLessProduct() {
      this.displayBaseTableLessProduct = false;
      this.emptyData = false;
      this.loading = true;
      this.process.run = true;
    },
    responseDataEndLessProduct(res) {
      this.loading = false;
      this.process.run = false;
      // this.total = res.data.summary;
      if (res.data.results.length <= 0) {
        this.emptyData = true;
        this.displayBaseTableLessProduct = false;
      } else {
        this.emptyData = false;
        this.displayBaseTableLessProduct = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;
    },
    // END BASE TABLE
    toFilter(item) {
      this.selected = item.id;
      this.filter.name = item.name;
    },
  },
};
</script>

<style>
.line {
  border-left: 6px solid #d31145 !important;
  transition: 0.3s;
}
</style>
